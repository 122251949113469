<!--
Copyright © MindMaze Holding SA 2020.  All Rights Reserved.
Unauthorized copying of this file, via any medium is strictly prohibited.
CONFIDENTIAL: This software is proprietary and confidential. It cannot be copied
and/or distributed without the express prior written permission of MindMaze Holding SA.
-->

<template>
    <div class="container-fluid mt-4" v-if="isIntentoSales">
        <b-breadcrumb>
            <b-breadcrumb-item :to="{ name: 'intentoDashboard' }">
                <b-icon
                    icon="house-fill"
                    scale="1.25"
                    shift-v="1.25"
                    aria-hidden="true"
                ></b-icon>
                Dashboard
            </b-breadcrumb-item>
            <b-breadcrumb-item :to="{ name: 'customerDashboard' }">
                Customer Management
            </b-breadcrumb-item>
        </b-breadcrumb>

        <b-alert :show="loading" variant="info">Loading...</b-alert>

        <!--- Search -->

        <b-row>
            <b-col cols="5">
                <b-input-group size="sm">
                    <b-input-group-prepend>
                        <b-button size="sm" @click.prevent="search()"
                            ><b-icon-search></b-icon-search
                        ></b-button>
                    </b-input-group-prepend>

                    <b-form-input
                        type="text"
                        v-model="searchQuery"
                        @keydown.enter.native="search()"
                    ></b-form-input>

                    <b-input-group-append>
                        <b-form-select size="sm" v-model="searchType" v-on:change="clearSearch">
                            <b-form-select-option value="license"
                                >License</b-form-select-option
                            >
                            <b-form-select-option value="device"
                                >Device</b-form-select-option
                            >
                            <b-form-select-option value="tablet"
                                >Tablet</b-form-select-option
                            >
                        </b-form-select>
                    </b-input-group-append>
                </b-input-group>
            </b-col>
        </b-row>

        <b-row>
            <b-col cols="5">
                <table class="table table-striped">
                    <tbody>
                        <tr v-for="result in searchResults" :key="result.id">
                            <td v-if="searchType == 'license'">
                                License # {{ result.license.id }}
                                <b-button
                                    variant="outline-primary"
                                    size="sm"
                                    class="m-1"
                                    :to="{ name: 'siteStatistics', params: {customerId: result.customerId, siteId: result.siteId }}" 
                                    exact exact-active-class="active"
                                    ><b-icon-box-arrow-up-right></b-icon-box-arrow-up-right>
                                </b-button>
                            </td>
                            <td v-if="searchType == 'device'">
                                Device # {{ result.intentoPro.id }}
                                <b-button
                                    variant="outline-primary"
                                    size="sm"
                                    class="m-1"
                                    :to="{ name: 'siteDevices', params: {customerId: result.customerId, siteId: result.siteId }}" 
                                    exact exact-active-class="active"
                                    ><b-icon-box-arrow-up-right></b-icon-box-arrow-up-right>
                                </b-button>
                            </td>
                            <td v-if="searchType == 'tablet'">
                                Tablet # {{ result.tablet.id }}
                                <b-button
                                    variant="outline-primary"
                                    size="sm"
                                    class="m-1"
                                    :to="{ name: 'siteTablets', params: {customerId: result.customerId, siteId: result.siteId }}" 
                                    exact exact-active-class="active"
                                    ><b-icon-box-arrow-up-right></b-icon-box-arrow-up-right>
                                </b-button>

                            </td>
                        </tr>
                    </tbody>
                </table>
            </b-col>
        </b-row>

        <!--- End Search -->

        <b-row class="mt-3">
            <b-col>
                <table class="table table-striped">
                    <thead>
                        <tr>
                            <th>Company</th>
                            <th>Address</th>
                            <th>City</th>
                            <th>Country</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="customer in customers" :key="customer.id">
                            <td>{{ customer.company }}</td>
                            <td>{{ customer.address }}</td>
                            <td>{{ customer.city }}</td>
                            <td>{{ customer.country }}</td>
                            <td class="text-right">
                                <b-row>
                                    <b-button
                                        variant="outline-primary"
                                        size="sm"
                                        class="m-1"
                                        @click.prevent="
                                            $router.push(
                                                '/customer-account/' +
                                                    customer.id +
                                                    '/sites'
                                            )
                                        "
                                    >
                                        <b-icon-box-arrow-up-right></b-icon-box-arrow-up-right>
                                    </b-button>
                                    <b-button
                                        variant="outline-primary"
                                        size="sm"
                                        class="my-1"
                                        @click.prevent="
                                            deleteCustomer(customer.id)
                                        "
                                    >
                                        <b-icon-trash></b-icon-trash>
                                    </b-button>
                                </b-row>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </b-col>

            <b-modal
                id="modal-add-customer"
                size="lg"
                @ok="saveCustomer"
                ok-title="Save Customer"
                :title="
                    selectedCustomer.id
                        ? 'Edit Customer ID#' + selectedCustomer.id
                        : 'New Customer'
                "
            >
                <form @submit.prevent="saveCustomer">
                    <b-row>
                        <b-col>
                            <b-form-group label="Company">
                                <b-form-input
                                    type="text"
                                    v-model="selectedCustomer.company"
                                ></b-form-input>
                            </b-form-group>
                            <b-form-group label="Address">
                                <b-form-input
                                    type="text"
                                    v-model="selectedCustomer.address"
                                ></b-form-input>
                            </b-form-group>
                            <b-form-group label="City">
                                <b-form-input
                                    type="text"
                                    v-model="selectedCustomer.city"
                                ></b-form-input>
                            </b-form-group>
                            <b-form-group label="Zip Code">
                                <b-form-input
                                    type="text"
                                    v-model="selectedCustomer.zipCode"
                                ></b-form-input>
                            </b-form-group>
                            <b-form-group label="Country">
                                <b-form-input
                                    type="text"
                                    v-model="selectedCustomer.country"
                                ></b-form-input>
                            </b-form-group>
                            <b-form-group label="VAT Number">
                                <b-form-input
                                    type="text"
                                    v-model="selectedCustomer.vatNumber"
                                ></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col>
                            <b-form-group label="Contact First name">
                                <b-form-input
                                    type="text"
                                    v-model="selectedCustomer.contactFirstName"
                                ></b-form-input>
                            </b-form-group>
                            <b-form-group label="Contact Last name">
                                <b-form-input
                                    type="text"
                                    v-model="selectedCustomer.contactLastName"
                                ></b-form-input>
                            </b-form-group>
                            <b-form-group label="Contact Phone">
                                <b-form-input
                                    type="text"
                                    v-model="selectedCustomer.contactPhone"
                                ></b-form-input>
                            </b-form-group>
                            <b-form-group label="Contact email">
                                <b-form-input
                                    type="text"
                                    v-model="selectedCustomer.contactEmail"
                                ></b-form-input>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </form>
                    <div class="form-group">
                        <div v-if="errorMessage" class="alert alert-danger" role="alert">
                            {{ errorMessage }}
                        </div>
                    </div>
            </b-modal>
        </b-row>

        <b-row>
            <b-col>
                <b-button
                    v-b-modal.modal-add-customer
                    size="sm"
                    class="m-1"
                    variant="primary"
                >
                    <b-icon-plus font-scale="1.3"></b-icon-plus>Add new customer
                </b-button>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import ApiService from "@/services/api.service";
import { mapGetters } from "vuex";

export default {
    data() {
        return {
            loading: false,
            customers: [],
            selectedCustomer: {},
            // Search
            searchType: "license",
            searchQuery: "",
            searchResults : [],
            errorMessage: ''
        };
    },
    async created() {
        this.refreshCustomers();
    },
    computed: {
        ...mapGetters("auth", ["isIntentoSales"]),
    },
    methods: {
        async refreshCustomers() {
            this.loading = true;
            this.customers = await ApiService.getCustomerAccounts();
            this.loading = false;
        },
        async populateCustomerToEdit(customer) {
            this.selectedCustomer = Object.assign({}, customer);
            this.errorMessage = '';
        },
        async saveCustomer(event) {
            event.preventDefault();
            if (this.selectedCustomer.id) {
                await ApiService.updateCustomerAccount(this.selectedCustomer.id, this.selectedCustomer).then(
                            () => { 
                                this.selectedCustomer = {}; // reset form
                                // Hide the modal manually
                                this.$nextTick(() => {
                                    this.$bvModal.hide("modal-add-customer");
                                });
                            },
                            (error) => {
                                    this.errorMessage =
                                    (error.response && error.response.data)
                                    || error.message
                                    || error.toString();
                                });
            } else {
                await ApiService.addCustomerAccount(this.selectedCustomer).then(
                            () => { 
                                this.selectedCustomer = {}; // reset form
                                // Hide the modal manually
                                this.$nextTick(() => {
                                    this.$bvModal.hide("modal-add-customer");
                                });
                            },
                            (error) => {
                                    this.errorMessage =
                                    (error.response && error.response.data)
                                    || error.message
                                    || error.toString();
                                });
            }
            await this.refreshCustomers();
        },
        async deleteCustomer(id) {
            if (confirm(`Are you sure you want to delete customer#${id} ?`)) {
                // if we are editing a customer we deleted, remove it from the form
                if (this.selectedCustomer.Id === id) {
                    this.selectedCustomer = {};
                }
                await ApiService.deleteCustomerAccount(id);
                await this.refreshCustomers();
            }
        },
        async search() {
            this.searchResults = [];
            if (this.searchType == "license") {
                this.searchResults = await ApiService.searchLicense(this.searchQuery);
            } else if (this.searchType == "device") {
                this.searchResults = await ApiService.searchDevice(this.searchQuery);
            } else if (this.searchType == "tablet") {
                this.searchResults = await ApiService.searchTabletDevice(this.searchQuery);
            }
        },
        clearSearch() {
            this.searchQuery = "";
            this.searchResults = [];
        }
    },
};
</script>
